if(document.body.id == "home") {
    const nativeDateTime = document.querySelectorAll('.native-date-time')
    const fallbackDateTime = document.querySelectorAll('.fallback-date-time')

    var ciYear = document.getElementById("form_ci-year")
    var ciMonth = document.getElementById("form_ci-month")
    var ciDay = document.getElementById("form_ci-day")
    var ciHour = document.getElementById("form_ci-hour")
    var ciMinute = document.getElementById("form_ci-minute")

    var coYear = document.getElementById("form_co-year")
    var coMonth = document.getElementById("form_co-month")
    var coDay = document.getElementById("form_co-day")
    var coHour = document.getElementById("form_co-hour")
    var coMinute = document.getElementById("form_co-minute")

    var checkInDate = document.getElementById("form_check-in-date")
    var checkInTime = document.getElementById("form_check-in-time")

    var checkOutDate = document.getElementById("form_check-out-date")
    var checkOutTime = document.getElementById("form_check-out-time")


    const getDateStr = (dt) => {
	var dd = String(dt.getDate()).padStart(2, '0')
	var mm = String(dt.getMonth() + 1).padStart(2, '0') //January is 0!
	var yyyy = dt.getFullYear()	

	return yyyy + "-" + mm + "-" + dd
    }

    const getDates = (dt="") => {
	var today = dt == "" ? new Date() : new Date(dt)

	var tomorrow = dt == "" ? new Date() : new Date(dt)
	tomorrow.setDate(tomorrow.getDate() + 1)

	return {
	    "today": getDateStr(today),
	    "tomorrow": getDateStr(tomorrow)
	}
    }


    const populateYears = (yearSel) => {
	var today = new Date()
	var thisYear = today.getFullYear()

	for (var i=0; i <= 2; i++) {
	    var option = document.createElement('option')
	    option.textContent = thisYear + i
	    yearSel.appendChild(option)
	}
    }

    const populateDays = (daySelect, yearSelect, month) => {
	// delete the current set of <option> elements out of the
	// day <select>, ready for the next set to be injected
	while(daySelect.firstChild){
	    daySelect.removeChild(daySelect.firstChild)
	}

	// Create variable to hold new number of days to inject
	var dayNum

	// 31 or 30 days?
	if(month === '01' || month === '03' || month === '05' || month === '07' || month === '08' || month === '10' || month === '12') {
	    dayNum = 31
	} else if(month === '04' || month === '06' || month === '09' || month === '11') {
	    dayNum = 30
	} else {
	    // If month is February, calculate whether it is a leap year or not
	    var year = yearSelect.value
	    var isLeap = new Date(year, 1, 29).getMonth() == 1
	    isLeap ? dayNum = 29 : dayNum = 28
	}

	// inject the right number of new <option> elements into the day <select>
	for(i = 1; i <= dayNum; i++) {
	    var option = document.createElement('option')
	    option.textContent = i
	    option.value = String(i).padStart(2, '0')
	    daySelect.appendChild(option)
	}
    }


    const setFallbackDates = (dt="") => {
	var dts = getDates(dt)
	var tdy = dts['today'].split("-")
	var tmr = dts['tomorrow'].split("-")

	ciYear.value = tdy[0]
	ciMonth.value = tdy[1]
	populateDays(ciDay, ciYear, ciMonth.value)
	ciDay.value = tdy[2]
	checkInDate.value = tdy.join("-")

	coYear.value = tmr[0]
	coMonth.value = tmr[1]
	populateDays(coDay, coYear, coMonth.value)
	coDay.value = tmr[2]
	checkOutDate.value = tmr.join("-")
    }


    const setFallbackDatesFromVal = () => {
	dtStr = ciYear.value + "-" + ciMonth.value + "-" + ciDay.value
	setFallbackDates(dtStr)
    }


    const isAfterCIDate = (coDateStr) => {
	var ciDate = new Date(checkInDate.value)
	var coDate = new Date(coDateStr)
	return ciDate < coDate
    }


    for(f of fallbackDateTime) {
	f.style.display = "none"
    }

    // check if input type date is supported
    var test = document.createElement('input')
    test.type = "date"

    // not supported
    if(test.type=="text") {
	// show fallback controls
	for(f of fallbackDateTime) {
	    f.style.display = "flex"
	}
	// hide native controls
	for (n of nativeDateTime) {
	    n.style.display = "none"
	}

	// populate the years and days
	populateYears(ciYear)
	populateYears(coYear)

	setFallbackDates()

	ciYear.addEventListener("change", () => {
	    ciMonth.value = "01"
	    populateDays(ciDay, ciYear, ciMonth.value)
	    ciDay.value = "01"

	    setFallbackDatesFromVal()
	}, false)

	ciMonth.addEventListener("change", () => {
	    populateDays(ciDay, ciYear, ciMonth.value)
	    ciDay.value = "01"

	    setFallbackDatesFromVal()
	}, false)

	ciDay.addEventListener("change", () => {
	    setFallbackDatesFromVal()
	}, false)

	ciHour.addEventListener("change", () => {
	    checkInTime.value = ciHour.value + ":" + ciMinute.value
	}, false)

	ciMinute.addEventListener("change", () => {
	    checkInTime.value = ciHour.value + ":" + ciMinute.value
	}, false)

	coYear.addEventListener("change", () => {
	    // if new potential date is after CI date, go ahead
	    if(isAfterCIDate(coYear.value + "-01-01")) {
		coMonth.value = "01"
		populateDays(coDay, coYear, coMonth.value)
		coDay.value = "01"
		checkOutDate.value = coYear.value + "-" + coMonth.value + "-" + coDay.value
		// otherwise, reset year to old value
	    } else {
		coYear.value = checkOutDate.value.split("-")[0]
	    }
	}, false)

	coMonth.addEventListener("change", () => {
	    if(isAfterCIDate(coYear.value + "-" + coMonth.value + "-01")) {
		populateDays(coDay, coYear, coMonth.value)
		coDay.value = "01"
		checkOutDate.value = coYear.value + "-" + coMonth.value + "-" + coDay.value
	    } else {
		coMonth.value = checkOutDate.value.split("-")[1]
	    }
	}, false)

	coDay.addEventListener("change", () => {
	    if(isAfterCIDate(coYear.value + "-" + coMonth.value + "-" + coDay.value)) {
		checkOutDate.value = coYear.value + "-" + coMonth.value + "-" + coDay.value
	    } else {
		coDay.value = checkOutDate.value.split("-")[2]
	    }
	}, false)

	coHour.addEventListener("change", () => {
	    checkOutTime.value = coHour.value + ":" + coMinute.value
	}, false)

	coMinute.addEventListener("change", () => {
	    checkOutTime.value = coHour.value + ":" + coMinute.value
	}, false)

	// native date picker
    } else {
	// set value and minimum to today
	var dates = getDates()

	checkInDate.setAttribute("value", dates['today'])
	checkInDate.setAttribute("min", dates['today'])
	checkOutDate.setAttribute("value", dates['tomorrow'])
	checkOutDate.setAttribute("min", dates['tomorrow'])

	// change check-out date when check-in date changes
	checkInDate.addEventListener("change", () => {
	    var inDate = checkInDate.value
	    var newDates = getDates(inDate)

	    checkOutDate.setAttribute("value", newDates['tomorrow'])
	    checkOutDate.setAttribute("min", newDates['tomorrow'])
	}, false)
    }
}

if(document.body.id == "success") {
    var countDown = 20
    const counterSpan = document.getElementById("counter-time")

    timerInt = window.setInterval(() => {
	countDown--;
	if(countDown == 0) {
	    clearInterval(timerInt)
	    window.location = '/'
	}
	counterSpan.innerHTML = countDown
    }, 1000)
}

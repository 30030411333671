// create overlay
const createRoomOverlay = () => {
    return new Promise(resolve => {
	var overLord = document.createElement('div')
	overLord.classList.add('tr-ro')
	overLord.id = "tr-ro"
	document.body.appendChild(overLord)
	document.body.classList.add('tr-ro-no')
	window.setTimeout(() => {
	    overLord.classList.add('tr-ro--a')
	    resolve(overLord)
	}, 100)
    })
}

const destroyRoomOverlay = (e) => {
    e.preventDefault()
    var o = document.getElementById("tr-ro")
    o.classList.remove("tr-ro--a")
    window.setTimeout(() => {
	document.body.removeChild(o)
	document.body.classList.remove("tr-ro-no")
    }, 1200)
}


const createRoomOverlayToolbar = () => {
    return new Promise(resolve => {
	var toolBar = document.createElement('div')
	toolBar.classList.add('tr-ro-t')

	// back button
	var backBtn = document.createElement('a')
	backBtn.classList.add('tr-ro-b')
	backBtn.href = "#"
	backBtn.appendChild(document.createTextNode('\u2190'))
	var bbSpan = document.createElement('span')
	bbSpan.appendChild(document.createTextNode(' Rooms'))
	backBtn.appendChild(bbSpan)
	toolBar.appendChild(backBtn)

	backBtn.addEventListener("click", destroyRoomOverlay, false)

	resolve(toolBar)
    }) 
}

const appendRoomData = (dataURL) => {
    return new Promise(resolve => {
	fetch(dataURL).then(response => response.text()).then(data => {
	    var tmp = document.createElement('div')
	    tmp.innerHTML = data
	    var roomData = tmp.getElementsByTagName('main')[0]
	    roomData.id = "tr-ro-d"
	    resolve(roomData)
	})
    })
}


const handleRoomLinks = (e) => {
    e.preventDefault()
    const dataURL = e.currentTarget.href

    createRoomOverlay().then(overLord => {
	createRoomOverlayToolbar().then(toolBar => {
	    overLord.appendChild(toolBar)
	    appendRoomData(dataURL).then(roomData => {
		overLord.appendChild(roomData)
	    })
	})
    })
}


const initRoomFetch = () => {
    if('fetch' in window) {
	const roomLinks = document.querySelectorAll("#rooms .ov a")
	for(var r of roomLinks) {
	    r.addEventListener("click", handleRoomLinks, false)
	}
    }
}

const handleNav = (e) => {
    e.preventDefault()

    // get the selector
    var sel = e.currentTarget.href.split("/")[3].substring(1)

    // get the current scroll pos
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop

    // get the top value for this section
    var topVal = document.getElementById(sel).getBoundingClientRect().top + scrollTop

    // set the hash
    window.location.hash = sel 

    // setting the hash jumps to that position, so go back to the original position
    window.scrollTo(0, scrollTop)

    // scroll to section (smoothly)
    window.scroll({ top: topVal, left: 0, behavior: "smooth" })
}


const initNav = () => {
    if(document.body.id == "home") {
	// get all nav links other than the logo
	const navLinks = document.querySelectorAll("#main-menu ul li:not(.logo) a")
	// add event listener to each link
	for(var a of navLinks) {
	    a.addEventListener("click", handleNav, false)
	}
	// add event listener to scroll text
	document.querySelector('.scrolltext').addEventListener("click", handleNav, false)
    }
}

//const checkActiveSection = (section) => {
    //var el = document.getElementById(section)
    //if(el.getBoundingClientRect().top <= 0) {
	//document.querySelector("#main-menu ul li a.active").classList.remove("active")
	//document.querySelector("#main-menu ul li a[href='/#heritage']").classList.add('active')
    //}
//}

//window.addEventListener("scroll", () => {
    //if(document.getElementById("heritage").getBoundingClientRect().top <= 0) {
	//document.querySelector("#main-menu ul li a[href='/#heritage']").classList.add('active')
    //} 
    
    //if(document.getElementById("rooms").getBoundingClientRect().top <= 0) {
	//document.querySelector("#main-menu ul li a[href='/#rooms']").classList.add('active')
    //}
//}, false)

// slideshow container class: .tr-ss
// slideshow will be automatically initialised for all 'a' elements inside a container
// options:
//	data-trss-captions: set to anything other than true to disable
//	data-trss-loop: set to anything other than true to disable

const Slideshow  = {
    src: null,
    showCaptions: true,
    loop: true,
    images: [],
    currentIndex: 0,

    // init: takes the clicked thumbnail link as a param
    init: function(obj) {
	// main source is the parent container
	this.src = obj.parentNode
	// set currentIndex to clicked thumbnail's position
	this.currentIndex = obj.dataset.trssPos
	// apply settings
	this.applySettings().then(() => {
	    //console.log('settings applied')
	    this.getImages().then(() => {
		this.createOverlay().then(() => {
		    this.insertImages().then(() => {
		    })	
		})
	    })
	})
    },


    // apply settings
    applySettings: function() {
	return new Promise(resolve => {
	    if("trssCaptions" in this.src.dataset)
		this.showCaptions = this.src.dataset.trssCaptions === "true"
	    if("trssLoop" in this.src.dataset)
		this.loop = this.src.dataset.trssLoop === "true"
	    resolve()
	})
    },


    // get all images
    getImages: function() {
	return new Promise(resolve => {
	    images = this.src.querySelectorAll('a')
	    this.images = []
	    for(var [i, a] of images.entries()) {
		a.dataset.pos = i
		this.images.push({  "target": a.href, "title": a.title })
	    }
	    resolve()
	})
    },


    // destroy overlay
    destroyOverlay: function() {
	return new Promise(resolve => {
	    if(document.getElementById("tr-ss-o")) {
		document.body.removeChild(document.getElementById("tr-ss-o"))
		document.body.classList.remove('tr-ss-no')
	    }
	    this.src = null
	    this.showCaptions = true
	    this.images = []
	    this.currentIndex = 0
	    resolve()
	})
    },


    // create overlay
    createOverlay: function() {
	return new Promise(resolve => {
	    // overlay
	    var overLord = document.createElement('div')
	    overLord.classList.add ("tr-ss-o")
	    overLord.classList.add("tr-ss-o--a")
	    overLord.id = "tr-ss-o"

	    // navigation
	    var overNav = document.createElement('div')
	    overNav.classList.add("tr-ss-n")

	    // nav: prev
	    var navPrev = document.createElement('a')
	    navPrev.classList = "tr-ss-l tr-ss-l--p"
	    navPrev.href = "#"
	    navPrev.appendChild(document.createTextNode('\u2190'))
	    navPrev.addEventListener("click", this.prevImage.bind(this), false)
	    overNav.appendChild(navPrev)

	    // nav: next
	    var navNext = document.createElement('a')
	    navNext.classList = "tr-ss-l tr-ss-l--n"
	    navNext.href = "#"
	    navNext.appendChild(document.createTextNode('\u2192'))
	    navNext.addEventListener("click", this.nextImage.bind(this), false)
	    overNav.appendChild(navNext)
	    overLord.appendChild(overNav)

	    // stage
	    var overStage = document.createElement('div')
	    overStage.classList.add("tr-ss-s")
	    overLord.appendChild(overStage)
	    this.stage = overStage

	    // close button
	    var overClose = document.createElement('a')
	    overClose.classList.add("tr-ss-x")
	    overClose.href = "#"
	    overClose.appendChild(document.createTextNode("X"))
	    overClose.addEventListener("click", (e) => {
		e.preventDefault()
		this.destroyOverlay()
	    }, false)
	    overLord.appendChild(overClose)

	    // add overlay to body
	    document.body.appendChild(overLord)

	    // disable overflow on body
	    document.body.classList.add('tr-ss-no')
	    resolve()
	})
    },

    // handle navigation
    handleNav: function(navType) {
	return new Promise(resolve => {
	    // get the 3 image containers
	    var prevImg = this.stage.children[0]
	    var currentImg = this.stage.children[1]
	    var nextImg = this.stage.children[2]

	    // remove active class on the current image
	    currentImg.classList.remove("tr-ss-i--a")
	    currentImg.classList.remove("tr-ss-i--a---p")

	    // prev
	    if(navType == "prev") {
		// remove next image container
		this.stage.removeChild(nextImg)
		// add current class on the last image
		prevImg.classList.add("tr-ss-i--a")
		prevImg.classList.add("tr-ss-i--a---p")
		// decrement current index and insert new previous image
		this.currentIndex = this.currentIndex == 0 ? this.images.length - 1 : this.currentIndex - 1
		const prevIndex = this.currentIndex == 0 ? this.images.length - 1 : this.currentIndex - 1
		this.getImage(prevIndex).then(prevImg => {
		    this.stage.insertBefore(prevImg, this.stage.firstChild)
		})

		// next
	    } else {
		// remove prev image container
		this.stage.removeChild(prevImg)
		// add current class on the last image
		nextImg.classList.add("tr-ss-i--a")
		// increment current index and insert new next image
		this.currentIndex = (this.currentIndex + 1)%this.images.length
		const nextIndex = (this.currentIndex + 1)%this.images.length
		this.getImage(nextIndex).then(nextImg => {
		    this.stage.appendChild(nextImg)
		})
	    }
	    resolve()
	})
    },


    // next image
    nextImage: function(e) {
	e.preventDefault()
	this.handleNav("next")
    },


    // prev image
    prevImage: function(e) {
	e.preventDefault()
	this.handleNav("prev")
    },


    // get image at index, with html
    getImage: function(index, active=false) {
	return new Promise(resolve => {
	    // container
	    var imgContainer = document.createElement('div')
	    imgContainer.classList.add("tr-ss-i")
	    if(active)
		imgContainer.classList.add("tr-ss-i--a")

	    // image
	    var img = new Image()
	    img.src = this.images[index].target
	    img.alt = this.images[index].title
	    imgContainer.appendChild(img)

	    // caption
	    if(this.showCaptions) {
		var imgCaption = document.createElement('div')
		imgCaption.classList.add("tr-ss-c")
		imgCaption.appendChild(document.createTextNode(this.images[index].title))
		imgContainer.appendChild(imgCaption)
	    }

	    resolve(imgContainer)
	})
    },


    // insert current, prev and next images on stage
    insertImages: function(stage) {
	return new Promise(resolve => {
	    this.stage.innerHTML = ""

	    // previous image
	    const prevIndex = this.currentIndex == 0 ? this.images.length - 1 : this.currentIndex - 1
	    this.getImage(prevIndex).then(prevImg => {
		this.stage.appendChild(prevImg)	

		// current image
		this.getImage(this.currentIndex, true).then(currentImg => {
		    this.stage.appendChild(currentImg)

		    // next image
		    const nextIndex = (this.currentIndex + 1)%this.images.length
		    this.getImage(nextIndex).then(nextImg => {
			this.stage.appendChild(nextImg)
		    })
		})
	    })
	    resolve()
	})
    }

}


const initSlideshows = () => {
    const containers = document.querySelectorAll('.tr-ss')
    for(var c of containers) {
	var thumbs = c.querySelectorAll('a')
	for(var [i, a] of thumbs.entries()) {
	    a.dataset.trssPos = i
	    a.addEventListener("click", (e) => {
		e.preventDefault()
		Object.create(Slideshow).init(e.currentTarget)
	    }, false)
	}
    }
}

